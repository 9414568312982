import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { pick } from "lodash";

import {
  addSpouseApi,
  updateHouseholdApi,
  updateSpouseApi,
} from "src/apiService";
import {
  fetchSpouseProfile,
  setProfileStep,
  updateHouseholdSuccess,
  updatePersonalInfo,
  UpdatePersonalInfoPayload,
} from "src/store/profileBuild/actions";
import { addSpouseSuccess, updateSpouse } from "src/store/system/actions";
import {
  getHousehold,
  getPersonalInfo,
  getSpousePersonalInfo,
} from "src/store/profileBuild/selector";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import {
  PERSONAL_INFO_KEYS,
  HOUSEHOLD_KEYS,
} from "src/store/profileBuild/selectorKeys";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import {
  FbAction,
  PersonalInfoSection,
  Gender,
  ViewComponent,
} from "src/interfaces";
import { formatZip, validateZip } from "src/utils";

// NEW PROFILE BUILDER
import FormError from "src/components/Global/FormError";
import moment from "moment";
import {
  EuiButtonEmpty,
  EuiTitle,
  EuiSpacer,
  EuiText,
  EuiFormRow,
  EuiDatePicker,
  EuiFieldText,
  EuiRadioGroup,
  EuiButton,
} from "@elastic/eui";
import { Col } from "react-bootstrap";
import {
  StyledBsAsterisk,
  StyledSpacer,
  StyledSpan,
  StyledFormRow,
  StyledEuiCallOut,
  StyledLink,
  StyledEuiButton,
  StyledEuiLink,
  StyledEuiHorizontalRule,
  StyledEuiButtonEmpty,
} from "src/components/Global/StyledComponents";
import {
  GENDER_OPTIONS,
  YES_NO_OPTIONS,
  generateOptions,
} from "src/constants/formOptions";
import { MainContainer } from "../../components";
import { calculateAge } from "src/utils";

export interface ChildAge {
  [key: string]: number;
}

export interface MyFormValues extends PersonalInfoSection {
  zip: string;
  enter_spouse_manually: boolean;
  married: string;
  spouse_is_member: string;
  spouse_email: string;
  spouse_first_name: string;
  spouse_last_name: string;
  spouse_sex: Gender;
  spouse_dob_day: number;
  spouse_dob_month: number;
  spouse_dob_year: number;
  spouse_password: string;
  has_children: string;
  children: Array<{ name: string; dob: any }>;
}

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const existingProfile = useSelector(getPersonalInfo);
  const spouseProfile = useSelector(getSpousePersonalInfo);
  const isMarried = useSelector(getIsMarried);
  const household = useSelector(getHousehold);
  const spouse = useSelector(spouseSelector);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const spouseIsLinked = spouse?.email && spouse.email.indexOf("spouse") < 0;
  const [childrenDobError, setChildrenDobError] = useState(false);
  const [errors, setErrors] = useState<any>({
    dob_month: false,
    dob_day: false,
    dob_year: false,
    sex: false,
    zip: false,
    married: false,
    spouse_first_name: false,
    spouse_sex: false,
    spouse_dob_day: false,
    spouse_dob_month: false,
    spouse_dob_year: false,
    has_children: false,
  });
  const [formState, setFormState] = useState<any>({
    phone_number: "",
    dob_month: 0,
    dob_day: 0,
    dob_year: 0,
    sex: "o",
    zip: "",
    married: isMarried ? "y" : "n",
    has_children: "n",
    children: [],
    spouse_is_member: "n",
    spouse_first_name: spouse?.first_name || "",
    spouse_last_name: spouse?.last_name || "",
    spouse_sex: "o",
    spouse_dob_day: 0,
    spouse_dob_month: 0,
    spouse_dob_year: 0,
    spouse_email: "",
    spouse_password: "",
    enter_spouse_manually: false,
  });

  const selectedDate =
    formState.dob_day && formState.dob_month && formState.dob_year
      ? moment(
          new Date(
            formState.dob_year,
            formState.dob_month - 1,
            formState.dob_day
          )
        )
      : null;

  const selectedDateSpouse =
    formState.spouse_dob_day &&
    formState.spouse_dob_month &&
    formState.spouse_dob_year
      ? moment(
          new Date(
            formState.spouse_dob_year,
            formState.spouse_dob_month - 1,
            formState.spouse_dob_day
          )
        )
      : null;

  function handleDateChange(fieldName: string, date: any, isSpouse = false) {
    if (date) {
      const momentDate = moment(date);
      setFormState((prevState: any) => {
        const updatedState = {
          ...prevState,
          [isSpouse ? "spouse_dob_year" : "dob_year"]: momentDate.year(),
          [isSpouse ? "spouse_dob_month" : "dob_month"]: momentDate.month() + 1,
          [isSpouse ? "spouse_dob_day" : "dob_day"]: momentDate.date(),
        };
        return updatedState;
      });

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [isSpouse ? "spouse_dob_day" : "dob_day"]: false,
        [isSpouse ? "spouse_dob_month" : "dob_month"]: false,
        [isSpouse ? "spouse_dob_year" : "dob_year"]: false,
      }));
    } else {
      setFormState((prevState: any) => ({
        ...prevState,
        [isSpouse ? "spouse_dob_month" : "dob_month"]: 0,
        [isSpouse ? "spouse_dob_day" : "dob_day"]: 0,
        [isSpouse ? "spouse_dob_year" : "dob_year"]: 0,
      }));

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [isSpouse ? "spouse_dob_day" : "dob_day"]: true,
        [isSpouse ? "spouse_dob_month" : "dob_month"]: true,
        [isSpouse ? "spouse_dob_year" : "dob_year"]: true,
      }));
    }
  }

  const handleRadioChange = (fieldName: string) => (id: string) => {
    const value = id.replace(`${fieldName}_`, "");
    const event = {
      target: {
        name: fieldName,
        value: value,
      },
    };
    setFormValue(event);

    setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName]: false }));
  };

  const addChild = () => {
    setFormState((prevState: any) => {
      const newChildren = [...prevState.children, { name: "", dob: null }];
      const newState = {
        ...prevState,
        children: newChildren,
        children_qty: newChildren.length,
      };
      return newState;
    });
  };

  const updateChildName = (index: number, name: string) => {
    setFormState((prevState: any) => {
      const newChildren = [...prevState.children];
      newChildren[index].name = name;
      return {
        ...prevState,
        children: newChildren,
      };
    });
    setErrors((current: any) => ({
      ...current,
      [`children.${index}.name`]: false,
    }));
  };

  const updateChildDob = (index: number, dob: Date) => {
    setFormState((prevState: any) => {
      const newChildren = [...prevState.children];
      newChildren[index] = { ...newChildren[index], dob: moment(dob) };
      return {
        ...prevState,
        children: newChildren,
      };
    });

    setChildrenDobError(!dob);
    setErrors((current: any) => ({
      ...current,
      [`children.${index}.dob`]: false,
    }));
  };

  const removeChild = (index: number) => {
    setFormState((prevState: any) => {
      const newChildren = [...prevState.children];
      newChildren.splice(index, 1);

      return {
        ...prevState,
        children: newChildren,
      };
    });
  };

  useEffect(() => {
    setFormState((current: any) => {
      const foundHousehold: any = household || {};
      const newFormState = {
        ...current,
        ...foundHousehold,
      };
      if (household.children.length) {
        newFormState.has_children = "y";
        newFormState.children = household.children.map((child) => ({
          name: child.name,
          dob: moment(child.dob),
        }));
      }
      return newFormState;
    });
  }, [household]);

  useEffect(() => {
    console.log({ spouseProfile });
    setFormState((current: any) => ({
      ...current,
      spouse_sex: spouseProfile.sex || "o",
      spouse_dob_day: spouseProfile.dob_day,
      spouse_dob_month: spouseProfile.dob_month,
      spouse_dob_year: spouseProfile.dob_year,
    }));
  }, [spouseProfile]);

  useEffect(() => {
    setFormState((current: any) => ({
      ...current,
      spouse_first_name: spouse?.first_name || "",
      spouse_last_name: spouse?.last_name || "",
    }));
  }, [spouse]);

  useEffect(() => {
    setFormState((current: any) => {
      const newFormState = {
        ...current,
        ...existingProfile,
      };
      return newFormState;
    });
  }, [existingProfile]);

  // useEffect(() => {
  //   console.log("Updated errors:", errors);
  // }, [errors]);

  // useEffect(() => {
  //   console.log("ERRORS", errors);
  // }, []);

  const setFormValue = (e: any) => {
    const target = e.target;
    let value = target.value;
    if (target.name === "zip") {
      value = formatZip(value);
    }
    const update = { ...formState, [target.name]: value };
    if (target.name === "has_children") {
      if (target.value === "y") {
        update.children_qty = 1;
        update.children = [{ name: "", dob: null }];
      } else {
        update.children = [];
      }
    }
    if (
      target.name === "spouse_first_name" ||
      target.name === "spouse_last_name" ||
      target.name === "spouse_dob_day" ||
      target.name === "spouse_dob_month" ||
      target.name === "spouse_dob_year"
    ) {
      update[target.name as keyof typeof update] = value as never;
    }
    setFormState(update);
    setErrors((prevErrors: any) => ({ ...prevErrors, [target.name]: false }));
  };

  const save = (proceed: boolean) => {
    if (
      Object.values(formState).every((value) => value === null || value === "")
    ) {
      return;
    }

    const fieldsToCheck: (keyof MyFormValues)[] = [
      "dob_year",
      "dob_month",
      "dob_day",
      "zip",
      "sex",
      "married",
      "spouse_first_name",
      "spouse_sex",
      "spouse_dob_day",
      "spouse_dob_month",
      "spouse_dob_year",
      "has_children",
    ];

    const newErrors: any = { ...errors };

    fieldsToCheck.forEach((fieldName) => checkError(fieldName, newErrors));
    if (formState.has_children === "y") {
      for (let i = 0; i < formState.children.length; i++) {
        const child = formState.children[i];
        if (!child.name) {
          newErrors[`children.${i}.name`] = true;
        }
        if (!child.dob) {
          newErrors[`children.${i}.dob`] = true;
        }
      }
    }

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      return;
    }

    const profileValues = pick(formState, PERSONAL_INFO_KEYS);
    const updatePayload: any = { update: profileValues };
    if (proceed) {
      updatePayload.nextPage = PROFILE_BUILD_STEPS.MY_EDUCATION;
    }
    const actions: Array<FbAction<UpdatePersonalInfoPayload>> = [
      updatePersonalInfo(updatePayload),
    ];
    let promise: Promise<any> = Promise.resolve();
    if (formState.married === "y" && !isMarried) {
      // if (formState.spouse_is_member === "y") {
      //   const spousePayload: AddSpousePayload = {};
      //   spousePayload.spouse = {
      //     email: formState.spouse_email,
      //     password: formState.spouse_password,
      //   };
      //   promise = authorizeSpouseApi(formState.spouse_email, formState.spouse_password)
      //   .then(() => {
      //     dispatch(addSpouseSuccess({ linked: true }));
      //     dispatch(fetchSpouseProfile());
      //   });
      // } else {
      const spousePayload: UpdatePersonalInfoPayload = {
        who: "spouse",
        update: {
          sex: formState.spouse_sex,
          dob_day: formState.spouse_dob_day,
          dob_month: formState.spouse_dob_month,
          dob_year: formState.spouse_dob_year,
        },
      };
      promise = addSpouseApi()
        .then(() =>
          updateSpouseApi({
            first_name: formState.spouse_first_name,
            last_name: formState.spouse_last_name,
          })
        )
        .then(() => {
          dispatch(addSpouseSuccess({ linked: false }));
          dispatch(
            updateSpouse({
              first_name: formState.spouse_first_name,
              last_name: formState.spouse_last_name,
            })
          );
          dispatch(fetchSpouseProfile());
        });
      actions.push(updatePersonalInfo(spousePayload));
    } else if (isMarried) {
      const spousePayload: UpdatePersonalInfoPayload = {
        who: "spouse",
        update: {
          sex: formState.spouse_sex,
          dob_day: formState.spouse_dob_day,
          dob_month: formState.spouse_dob_month,
          dob_year: formState.spouse_dob_year,
        },
      };
      promise = updateSpouseApi({
        first_name: formState.spouse_first_name,
        last_name: formState.spouse_last_name,
      }).then(() => {
        dispatch(
          updateSpouse({
            first_name: formState.spouse_first_name,
            last_name: formState.spouse_last_name,
          })
        );
      });
      actions.push(updatePersonalInfo(spousePayload));
    }
    const householdUpdate = pick(formState, HOUSEHOLD_KEYS);
    if (formState.has_children === "y") {
      householdUpdate.children = formState.children.map((child: any) => ({
        name: child.name,
        dob: child.dob.toDate().toISOString().slice(0, 10),
      }));
      let sortedChildren = [...formState.children];
      sortedChildren.sort((a, b) => a.dob - b.dob);
      sortedChildren = sortedChildren.slice(0, 5);
      householdUpdate.children_qty = sortedChildren.length;
      for (let i = 0; i < 5; i++) {
        if (sortedChildren.length > i) {
          householdUpdate[`child_${i + 1}_age`] = calculateAge(
            sortedChildren[i].dob.toDate()
          );
        } else {
          householdUpdate[`child_${i + 1}_age`] = 0;
        }
      }
    } else {
      householdUpdate.children = [];
      householdUpdate.children_qty = 0;
      for (let i = 1; i <= 5; i++) {
        householdUpdate[`child_${i}_age`] = 0;
      }
    }
    promise
      .then(() => updateHouseholdApi(householdUpdate))
      .then(() => {
        dispatch(updateHouseholdSuccess({ update: householdUpdate }));
        actions.forEach(dispatch);
      });
  };

  const next = () => {
    save(true);
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const onPrev = () => {
    save(false);
    dispatch(
      setProfileStep({
        step: PROFILE_BUILD_STEPS.WELCOME,
        updateProgress: false,
      })
    );
  };

  const saveAndExit = () => {
    save(false);
    dispatch(push("/profile-builder-continue"));
  };

  const checkError = (fieldName: keyof MyFormValues, newErrors: any) => {
    let hasError = false;

    if (fieldName === "zip") {
      hasError = !formState[fieldName] || !validateZip(formState[fieldName]);
    } else if (
      formState.married === "y" &&
      (fieldName === "spouse_first_name" ||
        fieldName === "spouse_dob_day" ||
        fieldName === "spouse_dob_month" ||
        fieldName === "spouse_dob_year")
    ) {
      hasError = !formState[fieldName];
    } else if (
      fieldName !== "spouse_first_name" &&
      fieldName !== "spouse_dob_day" &&
      fieldName !== "spouse_dob_month" &&
      fieldName !== "spouse_dob_year"
    ) {
      hasError = !formState[fieldName];
    }

    newErrors[fieldName] = hasError;

    return hasError;
  };

  const clearError = (fieldName: keyof MyFormValues) => {
    setErrors({ ...errors, [fieldName]: false });
  };

  if (!render) {
    return <div />;
  }

  let nextDisabled = false;
  let nextTooltip: string | undefined = undefined;
  // forOwn(errors, (value: boolean) => {
  //   if (value) {
  //     nextDisabled = true;
  //     nextTooltip = "Please check your input and complete all required fields.";
  //   }
  // });
  if (!nextDisabled) {
    ["dob_year", "dob_month", "dob_day", "zip", "sex"].forEach((key) => {
      if (!formState[key as keyof MyFormValues]) {
        nextDisabled = true;
        nextTooltip =
          "Please check your input and complete all required fields.";
      }
    });
  }

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <StyledFormRow>
          <Col lg={{ span: 9, offset: 2 }}>
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>
            <StyledSpacer size="32px" />
            <EuiTitle size="l" className="header-font">
              <h1>Tell us about you</h1>
            </EuiTitle>
            <EuiSpacer size="l" />
            <EuiText size="m">
              <p>Tell us a bit about you and we'll do the heavy lifting.</p>
            </EuiText>
            <StyledSpacer size="36px" />

            <EuiFormRow
              label={
                <StyledSpan>
                  Date of Birth
                  <StyledBsAsterisk />
                </StyledSpan>
              }
              isInvalid={
                !!(errors.dob_day || errors.dob_month || errors.dob_year)
              }
              helpText="Example: 05/20/1990"
              className="input-size"
            >
              <div>
                <EuiDatePicker
                  showIcon={false}
                  onChange={(date) => handleDateChange("dob", date)}
                  selected={selectedDate}
                  isInvalid={
                    !!(errors.dob_day || errors.dob_month || errors.dob_year)
                  }
                />
                {(errors.dob_day || errors.dob_month || errors.dob_year) && (
                  <FormError>This field is required.</FormError>
                )}
              </div>
            </EuiFormRow>
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <StyledSpan>
                  Zip Code
                  <StyledBsAsterisk />
                </StyledSpan>
              }
              isInvalid={errors.zip}
              className="input-size"
            >
              <EuiFieldText
                name="zip"
                value={formState.zip}
                onChange={setFormValue}
                onFocus={() => clearError("zip")}
                isInvalid={errors.zip}
              />
            </EuiFormRow>
            {errors.zip && <FormError>This field is required.</FormError>}
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <StyledSpan>
                  Gender
                  <StyledBsAsterisk />
                </StyledSpan>
              }
            >
              <EuiRadioGroup
                name="sex"
                options={GENDER_OPTIONS}
                idSelected={formState.sex}
                onChange={handleRadioChange("sex")}
                onFocus={() => clearError("sex")}
              />
            </EuiFormRow>

            {errors.sex && <FormError>This field is required.</FormError>}

            <StyledSpacer size="32px" />

            <EuiFormRow
              label={
                <StyledSpan>
                  Are you currently married?
                  <StyledBsAsterisk />
                </StyledSpan>
              }
            >
              <EuiRadioGroup
                disabled={isMarried}
                name="married"
                options={generateOptions("married", YES_NO_OPTIONS)}
                idSelected={`married_${formState.married}`}
                onChange={handleRadioChange("married")}
                onFocus={() => clearError("married")}
              />
            </EuiFormRow>

            {errors.married && <FormError>This field is required.</FormError>}

            {formState.married === "y" && !spouseIsLinked && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <StyledSpan>Is your spouse a FitBUX member?</StyledSpan>
                  }
                >
                  <EuiRadioGroup
                    name="spouse_is_member"
                    options={generateOptions(
                      "spouse_is_member",
                      YES_NO_OPTIONS
                    )}
                    idSelected={`spouse_is_member_${formState.spouse_is_member}`}
                    onChange={handleRadioChange("spouse_is_member")}
                  />
                </EuiFormRow>
                {formState.spouse_is_member === "y" && (
                  <>
                    <StyledSpacer size="32px" />

                    <StyledEuiCallOut
                      title="Join Spouse's FitBUX Account"
                      iconType="iInCircle"
                      style={{ maxWidth: 600 }}
                    >
                      <p>
                        If your spouse already has a FitBUX account, it's easier
                        to join their account than to create a new one. After
                        joining their account, send an email to{" "}
                        <StyledLink href="mailto:info@fitbux.com">
                          info@fitbux.com
                        </StyledLink>{" "}
                        to make sure you're only charged once. If you want to
                        create a separate account, you can continue below.
                      </p>
                    </StyledEuiCallOut>
                  </>
                )}
              </>
            )}
            {formState.married === "y" && (
              <>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  className="input-size"
                  label={
                    <StyledSpan>
                      Spouse's First Name
                      <StyledBsAsterisk />
                    </StyledSpan>
                  }
                  isInvalid={errors.spouse_first_name}
                >
                  <EuiFieldText
                    name="spouse_first_name"
                    value={formState.spouse_first_name}
                    onChange={setFormValue}
                    isInvalid={errors.spouse_first_name}
                    onFocus={() => clearError("spouse_first_name")}
                  />
                </EuiFormRow>
                {errors.spouse_first_name && (
                  <FormError>This field is required.</FormError>
                )}
                <StyledSpacer size="32px" />
                <EuiFormRow
                  className="input-size"
                  label={<StyledSpan>Spouse's Last Name</StyledSpan>}
                >
                  <EuiFieldText
                    name="spouse_last_name"
                    value={formState.spouse_last_name}
                    onChange={setFormValue}
                  />
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <StyledSpan>
                      Spouse's Date of Birth
                      <StyledBsAsterisk />
                    </StyledSpan>
                  }
                  helpText="Example: 05/20/1990"
                  className="input-size"
                  isInvalid={
                    !!(
                      errors.spouse_dob_day ||
                      errors.spouse_dob_month ||
                      errors.spouse_dob_year
                    )
                  }
                >
                  <div>
                    <EuiDatePicker
                      showIcon={false}
                      onChange={(date) =>
                        handleDateChange("spouse_dob", date, true)
                      }
                      selected={selectedDateSpouse}
                      isInvalid={
                        !!(
                          errors.spouse_dob_day ||
                          errors.spouse_dob_month ||
                          errors.spouse_dob_year
                        )
                      }
                    />
                    {(errors.spouse_dob_day ||
                      errors.spouse_dob_month ||
                      errors.spouse_dob_year) && (
                      <FormError>This field is required.</FormError>
                    )}
                  </div>
                </EuiFormRow>
                <StyledSpacer size="32px" />
                <EuiFormRow
                  label={
                    <StyledSpan>
                      Spouse's Gender
                      <StyledBsAsterisk />
                    </StyledSpan>
                  }
                  error={errors.spouse_sex}
                >
                  <EuiRadioGroup
                    name="spouse_sex"
                    options={generateOptions("spouse_sex", GENDER_OPTIONS)}
                    idSelected={`spouse_sex_${formState.spouse_sex}`}
                    onChange={handleRadioChange("spouse_sex")}
                    onFocus={() => clearError("spouse_sex")}
                  />
                </EuiFormRow>

                {errors.spouse_sex && (
                  <FormError>This field is required.</FormError>
                )}
              </>
            )}
            <StyledSpacer size="32px" />
            <EuiFormRow
              label={
                <StyledSpan>
                  Do you have any children?
                  <StyledBsAsterisk />
                </StyledSpan>
              }
            >
              <EuiRadioGroup
                name="has_children"
                options={generateOptions("has_children", YES_NO_OPTIONS)}
                idSelected={`has_children_${formState.has_children}`}
                onChange={handleRadioChange("has_children")}
              />
            </EuiFormRow>
            {errors.has_children && (
              <FormError>This field is required.</FormError>
            )}
            {formState.has_children === "y" && (
              <>
                <StyledSpacer size="32px" />
                {formState.children.map((child: any, index: number) => (
                  <>
                    <EuiFormRow key={index}>
                      <div>
                        <EuiFormRow
                          isInvalid={
                            !!errors.children &&
                            !!errors.children[index] &&
                            errors.children[index].name
                          }
                          label={
                            <StyledSpan>
                              Child's Name
                              <StyledBsAsterisk />
                            </StyledSpan>
                          }
                        >
                          <div>
                            <EuiFieldText
                              name={`children.${index}.name`}
                              type="text"
                              value={child.name}
                              onChange={(event) =>
                                updateChildName(index, event.target.value)
                              }
                              isInvalid={
                                !!errors.children &&
                                !!errors.children[index] &&
                                errors.children[index].name
                              }
                            />
                          </div>
                        </EuiFormRow>
                        {errors.children &&
                          errors.children[index] &&
                          errors.children[index].name && (
                            <FormError>This field is required.</FormError>
                          )}
                        <StyledSpacer size="32px" />
                        <EuiFormRow
                          label={
                            <StyledSpan>
                              Child's Date of Birth
                              <StyledBsAsterisk />
                            </StyledSpan>
                          }
                          helpText="Example: 05/20/1990"
                          isInvalid={childrenDobError}
                        >
                          <div>
                            <EuiDatePicker
                              showIcon={false}
                              selected={child.dob}
                              onChange={(date) =>
                                updateChildDob(
                                  index,
                                  date ? date.toDate() : new Date()
                                )
                              }
                              isInvalid={
                                errors.children &&
                                errors.children[index] &&
                                errors.children[index].dob
                              }
                            />
                            {childrenDobError && (
                              <FormError>This field is required.</FormError>
                            )}
                          </div>
                        </EuiFormRow>
                        <StyledSpacer size="32px" />
                        <div>
                          {formState.children.length > 1 && (
                            <>
                              <StyledEuiLink
                                onClick={() => removeChild(index)}
                                color="danger"
                              >
                                Delete
                              </StyledEuiLink>
                              <StyledSpacer size="24px" />
                            </>
                          )}
                        </div>
                        <StyledEuiHorizontalRule style={{ maxWidth: 428 }} />
                        <StyledSpacer size="32px" />
                      </div>
                    </EuiFormRow>
                  </>
                ))}
                <StyledEuiButton
                  color="text"
                  iconType="plus"
                  onClick={addChild}
                >
                  Add child
                </StyledEuiButton>
              </>
            )}
            <StyledSpacer size="48px" />
            <EuiButton
              className="btn-text"
              type="submit"
              color="primary"
              fill
              onClick={next}
              disabled={Object.values(errors).some((error) => error)}
            >
              Next
            </EuiButton>
            <StyledEuiButtonEmpty
              color="text"
              className="btn-text"
              onClick={saveAndExit}
            >
              Save and Exit
            </StyledEuiButtonEmpty>
            <StyledSpacer size="32px" />
          </Col>
        </StyledFormRow>
      </MainContainer>
    ),
    nextDisabled,
    nextTooltip,
    onPrev,
    onNext: next,
  });
};

export default MainForm;
