import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { range } from "lodash";

import {
  Box,
  makeStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  Select,
} from "@material-ui/core";

import { profileBuilderStyles } from "src/theme";

import CenterContainer from "../../Components/CenterContainer";
import Button from "src/components/Button";
import Radio from "src/components/Radio";
import { MONTHS } from "src/constants";
import { savePlan, updateCurrentPlan } from "src/store/planBuild/actions";
import { getCurrentPlan } from "src/store/planBuild/selector";
import {
  getGraduationYear,
  getSpouseInSchool,
  getUserInSchool,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { Plan, PlanViewComponent } from "src/interfaces";
import { DollarTextField } from "src/utils";

const useStyles = makeStyles(profileBuilderStyles);

const now = new Date();
const nowYear = now.getFullYear();
const nowMonth = now.getMonth() + 1;

const ParentPlusLoans: PlanViewComponent = ({
  initialValues,
  onClose,
  onSave,
  render,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const plan: Plan = useSelector(getCurrentPlan);
  const graduationYear = useSelector(getGraduationYear);
  const isMarried = useSelector(getIsMarried);
  const spouseInSchool = useSelector(getSpouseInSchool);
  const userInSchool = useSelector(getUserInSchool);
  const bothInSchool = isMarried && spouseInSchool && userInSchool;
  const initialMonth = initialValues
    ? +initialValues.date.slice(5, 7)
    : nowMonth;
  const initialYear = initialValues ? initialValues.date.slice(0, 4) : nowYear;
  const [formValues, setFormValues] = useState<any>(
    initialValues
      ? {
          ...initialValues,
          month: initialMonth,
          year: initialYear,
          repayment: initialValues.repayment ? "y" : "n",
        }
      : {
          year: nowYear,
          month: nowMonth,
          repayment: "y",
          who: userInSchool ? "applicant" : "spouse",
        }
  );

  const save = () => {
    let month = formValues.month;
    if (+month < 10) {
      month = "0" + +month;
    }
    const newItem = {
      type: "parent_loan",
      date: `${formValues.year}-${month}`,
      payment: formValues.payment,
      repayment: formValues.repayment === "y",
      who: formValues.who,
    };
    const planUpdate = {
      education: [...(plan.education || [])],
    };
    if (initialValues && initialValues.editIndex >= 0) {
      planUpdate.education[initialValues.editIndex] = newItem;
    } else {
      planUpdate.education.push(newItem);
    }
    dispatch(updateCurrentPlan(planUpdate));
    dispatch(savePlan(null));
    onSave();
  };

  const setFormValue = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    const newFormValues = { ...formValues, [field]: value };
    setFormValues(newFormValues);
  };

  return render({
    component: (
      <CenterContainer title="Parent Plus Loans" iconName="fb-scales-tripped">
        <Box className="flex items-center mt-4">
          <FormControl>
            <FormLabel className="mb-4">Amount Of Loan</FormLabel>
            <DollarTextField
              variant="outlined"
              name="payment"
              value={formValues.payment}
              onChange={setFormValue}
            />
          </FormControl>
          <FormControl className="ml-4">
            <FormLabel className="mb-4">
              Planned Date You'll Receive Funds
            </FormLabel>
            <Box className="flex">
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="month"
                onChange={setFormValue}
                value={formValues.month}
                className="mr-4"
              >
                {MONTHS.map((month: string, index: number) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Select
                variant="outlined"
                style={{ width: "150px" }}
                name="year"
                onChange={setFormValue}
                value={formValues.year}
              >
                {range(nowYear, graduationYear + 1).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormControl>
          {bothInSchool && (
            <FormControl className="ml-4">
              <FormLabel className="mb-4">Mine/Spouse</FormLabel>
              <Select
                variant="outlined"
                fullWidth
                value={formValues.whose}
                name="whose"
                placeholder="Select"
                onChange={setFormValue}
              >
                <MenuItem value="applicant">Mine</MenuItem>
                <MenuItem value="spouse">My spouse's</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <FormControl className="mt-4">
          <FormLabel className="mb-2">
            Will you be responsible for repaying the parent plus loans, or will
            your parents?
          </FormLabel>
          <RadioGroup
            value={formValues.repayment}
            name="repayment"
            onChange={setFormValue}
          >
            <FormControlLabel
              value={"y"}
              control={<Radio />}
              label="I'll be repaying them"
            />
            <FormControlLabel
              value={"n"}
              control={<Radio />}
              label="My parents will be repaying them"
            />
          </RadioGroup>
        </FormControl>
        <Button
          variant="outlined"
          fbColor="primary"
          onClick={onClose}
          className={styles.btnCancel}
        >
          Cancel
        </Button>
      </CenterContainer>
    ),
    nextLabel: "Save",
    onNext: save,
  });
};

export default ParentPlusLoans;
