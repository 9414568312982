import { createAction } from "redux-actions";
import { START, SUCCESS } from "../common";

import { Household } from "src/interfaces/household.interface";
import { Who } from "src/interfaces/common";
import {
  PersonalInfoSection,
  EducationSection,
  CareerSection,
  OtherSection,
  DebtsSection,
  Profile,
} from "src/interfaces/profile.interface";
import { PROFILE_BUILD_STEPS } from "./constants";

export interface UpdatePersonalInfoPayload {
  who?: Who;
  update: Partial<PersonalInfoSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateHouseholdPayload {
  update: Partial<Household>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateEducationPayload {
  who?: Who;
  update: Partial<EducationSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateCareerPayload {
  who?: Who;
  update: Partial<CareerSection>;
  nextPage: PROFILE_BUILD_STEPS;
}

export interface UpdateOtherHumanCapitalPayload {
  who?: Who;
  update: Partial<OtherSection>;
  nextPage: PROFILE_BUILD_STEPS;
}

export interface UpdateDebtsPayload {
  who?: Who;
  update: Partial<DebtsSection>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface UpdateProfilePayload {
  who?: Who;
  update: Partial<Profile>;
  nextPage?: PROFILE_BUILD_STEPS;
}

export interface SetProfileStepPayload {
  step: PROFILE_BUILD_STEPS;
  updateProgress: boolean;
}

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_SPOUSE_PROFILE = "FETCH_SPOUSE_PROFILE";
export const FETCH_HOUSEHOLD = "FETCH_HOUSEHOLD";
export const SET_PROFILE_STEP = "SET_PROFILE_STEP";
export const GET_PROFILE_PROGRESS = "GET_PROFILE_PROGRESS";
export const SET_PROFILE_PROGRESS = "SET_PROFILE_PROGRESS";
export const SET_PROFILE_COMPLETE = "SET_PROFILE_COMPLETE";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const UPDATE_HOUSEHOLD = "UPDATE_HOUSEHOLD";
export const INVITE_SPOUSE = "INVITE_SPOUSE";
export const FETCH_SCHOOLS = "FETCH_SCHOOLS";
export const FETCH_SCHOOLS_BY_LEVEL = "FETCH_SCHOOLS_BY_LEVEL"; // TODO: check school vs leveled school later
export const FETCH_SPECIALTIES = "FETCH_SPECIALITIES";
export const FETCH_FIELDS = "FETCH_UNDERGRAD_FIELDS";
export const FETCH_PROFESSIONS = "FETCH_PROFESSIONS";
export const FETCH_EMPLOYMENTS = "FETCH_EMPLOYMENTS";
export const FETCH_FELLOWSHIPS = "FETCH_FELLOWSHIPS";
export const FETCH_DESIGNATIONS = "FETCH_DESIGNATIONS";
export const UPDATE_EDUCATION = "UPDATE_HUMAN_CAPITAL";
export const UPDATE_CAREER = "UPDATE_CAREER";
export const UPDATE_OTHER_HUMAN_CAPITAL = "UPDATE_OTHER_HUMAN_CAPITAL";
export const UPDATE_DEBTS = "UPDATE_DEBTS";

export const updateProfile = createAction<UpdateProfilePayload>(
  UPDATE_PROFILE + START
);
export const fetchProfile = createAction(FETCH_PROFILE + START);
export const fetchSpouseProfile = createAction(FETCH_SPOUSE_PROFILE + START);
export const fetchHousehold = createAction(FETCH_HOUSEHOLD + START);
export const updateHousehold = createAction<UpdateHouseholdPayload>(
  UPDATE_HOUSEHOLD + START
);
export const updateHouseholdSuccess = createAction<UpdateHouseholdPayload>(
  UPDATE_HOUSEHOLD + SUCCESS
);
export const setProfileStep = createAction<SetProfileStepPayload>(
  SET_PROFILE_STEP
);
export const getProfileProgress = createAction(GET_PROFILE_PROGRESS + START);
export const setProfileProgress = createAction<number>(
  SET_PROFILE_PROGRESS + START
);
export const setProfileComplete = createAction(SET_PROFILE_COMPLETE + START);
export const updatePersonalInfo = createAction<UpdatePersonalInfoPayload>(
  UPDATE_PERSONAL_INFO + START
);
export const updatePersonalInfoSuccess = createAction<UpdatePersonalInfoPayload>(
  UPDATE_PERSONAL_INFO + SUCCESS
);
export const inviteSpouse = createAction(INVITE_SPOUSE + START);
export const fetchSchools = createAction(FETCH_SCHOOLS + START);
export const fetchSchoolsByLevel = createAction<number>(
  FETCH_SCHOOLS_BY_LEVEL + START
);
export const fetchSpecialties = createAction<number>(FETCH_SPECIALTIES + START);
export const fetchFields = createAction(FETCH_FIELDS + START);
export const fetchProfessions = createAction(FETCH_PROFESSIONS + START);
export const fetchEmployments = createAction(FETCH_EMPLOYMENTS + START);
export const fetchFellowships = createAction(FETCH_FELLOWSHIPS + START);
export const fetchDesignations = createAction(FETCH_DESIGNATIONS + START);

export const updateEducation = createAction<UpdateEducationPayload>(
  UPDATE_EDUCATION + START
);
export const updateCareer = createAction<UpdateCareerPayload>(
  UPDATE_CAREER + START
);
export const updateOther = createAction<UpdateOtherHumanCapitalPayload>(
  UPDATE_OTHER_HUMAN_CAPITAL + START
);
export const updateDebts = createAction<UpdateDebtsPayload>(
  UPDATE_DEBTS + START
);
