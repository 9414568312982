import React from "react";
import { usePlaidLink } from "react-plaid-link";

import { EuiButton } from "@elastic/eui";

interface Props {
  onClose: VoidFunction;
  userToken: string;
  setShowDialog: any;
  onSuccess: any;
}

const SinglePlaidControl = ({
  onClose,
  userToken,
  setShowDialog,
  onSuccess,
}: Props) => {
  const userConfig: any = {
    token: userToken,
    onSuccess: onSuccess("applicant"),
    onExit: onClose,
  };
  const { open: userOpen } = usePlaidLink(userConfig);

  const openPlaidApplicant = () => {
    setShowDialog(false);
    userOpen();
  };

  return (
    <EuiButton fill onClick={openPlaidApplicant}>
      Connect account
    </EuiButton>
  );
};

export default SinglePlaidControl;
