// In reducers.ts

import { OPEN_TOUR_CARD, CLOSE_TOUR_CARD } from "./actions";
import {
  SET_PROFILE_STEP,
  SetProfileStepPayload,
} from "../profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "../profileBuild/constants";

export interface TourCardState {
  isTourCardOpen: boolean;
}

// Define the ActionType to include SET_PROFILE_STEP
export type ActionType =
  | { type: typeof OPEN_TOUR_CARD }
  | { type: typeof CLOSE_TOUR_CARD }
  | { type: typeof SET_PROFILE_STEP; payload: SetProfileStepPayload };

const initialState: TourCardState = {
  isTourCardOpen: true,
};

export default function tourCardReducer(
  state = initialState,
  action: ActionType
) {
  switch (action.type) {
    case OPEN_TOUR_CARD:
      return { ...state, isTourCardOpen: true };
    case CLOSE_TOUR_CARD:
      return { ...state, isTourCardOpen: false };
    case SET_PROFILE_STEP:
      return {
        ...state,
        isTourCardOpen: action.payload.step === PROFILE_BUILD_STEPS.WELCOME,
      };
    default:
      return state;
  }
}
