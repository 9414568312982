import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { pick } from "lodash";
import {
  EuiFormRow,
  EuiButton,
  EuiFormControlLayout,
  EuiButtonEmpty,
  EuiText,
  EuiTitle,
  EuiRadio,
  EuiComboBox,
} from "@elastic/eui";
import { Col } from "react-bootstrap";
import {
  StyledSpacer,
  StyledEuiButtonEmpty,
  LabelAsterisk,
  LabelStyle,
  StyledFormRow,
  StyledEuiButton,
} from "src/components/Global/StyledComponents";
import { closeTourCard } from "src/store/tourcard/actions";
import { setProfileProgressApi } from "src/apiService";
import { ViewComponent } from "src/interfaces";
import { SUCCESS } from "src/store/common";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import {
  fetchProfessions,
  setProfileStep,
} from "src/store/profileBuild/actions";
import {
  updateProfile,
  SET_PROFILE_PROGRESS,
} from "src/store/profileBuild/actions";

import {
  getCurrentStep,
  getProfileLoading,
  getProfessions,
  getProfile,
  getProfileLoaded,
  getSpouseProfile,
} from "src/store/profileBuild/selector";
import { getIsMarried, spouseSelector } from "src/store/system/selector";
import { MainContainer } from "../../components";
import LanguageSection from "./LanguageSection";
import FormError from "src/components/Global/FormError";

interface InputFieldDefinition {
  key: string;
  label: string;
}

const inputFields: InputFieldDefinition[][] = [
  [
    {
      //   key: "qual_designation_required",
      //   label: "Does your profession require a license or professional designation in order to work?",
      // }, {
      //   key: "qual_license_exam",
      //   label: "Did you pass the exams on the first attempt?",
      // },  {
      //   key: "qual_designation_voluntary",
      //   label: "Do you have a designation that you voluntarily obtained?",
      // }, {
      //   key: "qual_desig_exam",
      //   label: "If you had to pass exams to obtain the designation, did you pass on the first attempt?",
      // },  {
      key: "qual_networking",
      label: "Are you a member of any professional networking associations?",
    },
    {
      key: "qual_ncaa_sports",
      label: "Did you play a club or NCAA sport in college?",
    },
    {
      key: "qual_marathons",
      label:
        "Do you run marathons, triathlons, or any other endurance type of contests?",
    },
    {
      key: "qual_military",
      label: "Have you served in the military without discharge?",
    },
  ],
  [
    {
      key: "qual_ap_scholar",
      label: "Were you an AP Scholar?",
    },
    {
      key: "qual_intl_bacc",
      label: "Did you graduate from an international baccalaureate program?",
    },
    {
      key: "qual_scholarship",
      label: "Were you offered a merit or academic scholarship for college?",
    },
    {
      key: "qual_full_scholarship",
      label:
        "Were you offered a scholarship that covered your entire tuition cost?",
    },
  ],
  [],
  [
    // {
    //   key: "qual_fin_proactive",
    //   label:
    //     "Do you use personal financial management software to manage your finances?",
    // },
    {
      key: "delinquency",
      label: "Have you ever been delinquent on a loan?",
    },
  ],
];

const keys = [
  ...inputFields.flat().map((item) => item.key),
  "lang",
  "lang_1",
  "lang_speaking_1",
  "lang_reading_1",
  "lang_writing_1",
  "lang_2",
  "lang_speaking_2",
  "lang_reading_2",
  "lang_writing_2",
];
const requiredKeys = [
  "profession",
  "qual_networking",
  "qual_ncaa_sports",
  "qual_marathons",
  "qual_military",
];

const STEPS = [
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_1,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_1,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_2,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_2,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_3,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_3,
  PROFILE_BUILD_STEPS.MY_HUMAN_CAPITAL_4,
  PROFILE_BUILD_STEPS.SPOUSE_HUMAN_CAPITAL_4,
];

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const isMine: boolean = (currentStep || "").indexOf("SPOUSE") !== 0;
  const step = STEPS.indexOf(currentStep);
  const page = Math.floor(step / 2);
  const loading = useSelector(getProfileLoading);
  const loaded = useSelector(getProfileLoaded);
  const spouse = useSelector(spouseSelector);
  const myProfile = useSelector(getProfile);
  const spouseProfile = useSelector(getSpouseProfile);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);
  const isMarried = useSelector(getIsMarried);
  const professions = useSelector(getProfessions);

  const [formValues, setFormValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({
    profession: false,
    qual_networking: false,
    qual_ncaa_sports: false,
    qual_marathons: false,
    qual_military: false,
  });
  const spouseFirstName = spouse?.first_name || "";
  const your = isMine ? "your" : `${spouseFirstName}'s`;
  const yourCaps = isMine ? "Your" : your;
  const youSpeak = isMine ? "you speak" : `${spouseFirstName} speaks`;
  const TITLES = [
    `${yourCaps} Human Capital`,
    `${yourCaps} education`,
    `${yourCaps} languages`,
    `${yourCaps} financial proactiveness`,
  ];

  const EXPLAINERS = [
    `Human Capital looks at your behavior and how it affects your projected wealth and risk. Tell us a few things about ${your} background so we can recommend the best course of action. You can answer the other questions later on your dashboard.`,
    "Our data indicates that these factors reduce the risk to your income.",
    `The number of languages ${youSpeak} lowers the risk to ${your} income and increases ${your} income potential.`,
    "",
  ];
  const explainer = EXPLAINERS[page];

  useEffect(() => {
    if (!loaded.professions && !loading.professions) {
      dispatch(fetchProfessions());
    }
  }, [dispatch]);

  useEffect(() => {
    const profile = isMine ? myProfile : spouseProfile;
    setFormValues((current: any) => ({
      ...current,
      ...pick(profile, keys),
      two_langs: profile.lang_2 ? "y" : "n",
      profession: profile.profession,
    }));
  }, [isMine, myProfile, spouseProfile]);

  const save = () => {
    const update = { ...formValues };
    delete update.two_langs;
    dispatch(
      updateProfile({
        who: isMine ? "applicant" : "spouse",
        update,
      })
    );
  };

  const selectValue = (key: string, value: string) => {
    setFormValues((current: any) => ({
      ...current,
      [key]: value,
    }));
  };

  const onPrev = () => {
    save();
    dispatch(
      setProfileStep({
        step:
          step === 0
            ? PROFILE_BUILD_STEPS.HUMAN_CAPITAL_INTRO
            : STEPS[step - (isMarried ? 1 : 2)],
        updateProgress: false,
      })
    );
  };

  const validate = () => {
    let valid = true;
    const newErrors: any = {};
    for (let i = 0; i < requiredKeys.length; i++) {
      const key = requiredKeys[i];
      if (!formValues[key]) {
        newErrors[key] = true;
        valid = false;
      }
    }
    setErrors(newErrors);
    return valid;
  };

  const onNext = () => {
    if (validate()) {
      save();
      dispatch(
        setProfileStep({
          step: STEPS[step + (isMarried ? 1 : 2)],
          updateProgress: false,
        })
      );
      dispatch(closeTourCard());
    }
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const saveAndExit = () => {
    save();
    dispatch(closeTourCard());
    dispatch(push("/profile-builder-continue"));
  };

  const finish = () => {
    if (validate()) {
      setProfileProgressApi(8).then(() => {
        dispatch({ type: SET_PROFILE_PROGRESS + SUCCESS, payload: 8 });
        save();
        dispatch(push("/plan-summary"));
      });
      dispatch(closeTourCard());
    }
  };

  const formatLabelForSpouse = (label: string) =>
    label
      .replace("Are you", `Is ${spouseFirstName}`)
      .replace("Were you", `Was ${spouseFirstName}`)
      .replace("Did you", `Did ${spouseFirstName}`)
      .replace("Do you", `Does ${spouseFirstName}`)
      .replace("Have you", `Has ${spouseFirstName}`)
      .replace("your", "their");

  const renderItem = (item: InputFieldDefinition, index: number) => {
    const onChange = (e: any) => {
      setFormValues((current: any) => ({
        ...current,
        [item.key]: e.target.value,
      }));
      setErrors((current: any) => ({
        ...current,
        [item.key]: false,
      }));
    };
    return (
      <React.Fragment key={item.key}>
        {index > 0 && <StyledSpacer size="32px" />}
        <EuiFormRow
          label={
            <LabelStyle>
              {isMine ? item.label : formatLabelForSpouse(item.label)}
              <LabelAsterisk />
            </LabelStyle>
          }
          error={errors[item.key] ? "This field is required" : undefined}
          className="input-size"
        >
          <EuiFormControlLayout>
            <EuiRadio
              name={item.key}
              id={`${item.key}_n`}
              label="No"
              checked={formValues[item.key] === "n"}
              value="n"
              onChange={onChange}
            />
            <EuiRadio
              name={item.key}
              id={`${item.key}_y`}
              label="Yes"
              checked={formValues[item.key] === "y"}
              value="y"
              onChange={onChange}
            />
            {page === 0 && (
              <EuiRadio
                name={item.key}
                id={`${item.key}_na`}
                label="Does not apply to me"
                checked={formValues[item.key] === "na"}
                value="na"
                onChange={onChange}
              />
            )}
          </EuiFormControlLayout>
        </EuiFormRow>
      </React.Fragment>
    );
  };

  if (!render) {
    return <div />;
  }

  let selectedProfession: any[] = [];

  const finalStep = isMarried ? 7 : 6;
  const isFinalStep = step === finalStep;

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <StyledFormRow>
          <Col lg={{ span: 6, offset: 2 }}>
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>
            <StyledSpacer size="32px" />
            <EuiTitle size="l" className="header-font">
              <h1>{TITLES[page]}</h1>
            </EuiTitle>
            <StyledSpacer size="32px" />
            {!!explainer && (
              <>
                <EuiText size="m">
                  <p>{explainer}</p>
                </EuiText>
                <StyledSpacer size="32px" />
              </>
            )}
            {page === 0 && (
              <>
                <EuiFormRow
                  label={
                    <LabelStyle>
                      What is {your} profession or what will be {your}{" "}
                      profession post graduation? *
                    </LabelStyle>
                  }
                  className="input-size"
                >
                  <EuiComboBox
                    rowHeight={40}
                    isClearable={false}
                    options={professions.map((item: any) => {
                      const value = {
                        label: item.name,
                        key: item.id,
                      };
                      if (formValues.profession === item.id) {
                        selectedProfession = [value];
                      }
                      return value;
                    })}
                    selectedOptions={selectedProfession}
                    onChange={(options) => {
                      if (options.length > 0) {
                        setFormValues((current: any) => ({
                          ...current,
                          profession: options[0].key,
                        }));
                        setErrors((current: any) => ({
                          ...current,
                          profession: false,
                        }));
                      } else {
                        // Clear the selection if the options array is empty
                        setFormValues((current: any) => ({
                          ...current,
                          profession: "",
                        }));
                      }
                    }}
                    singleSelection={{ asPlainText: true }}
                    isInvalid={errors.profession}
                  />
                </EuiFormRow>
                {page === 0 && errors.profession && (
                  <FormError>Please select a profession.</FormError>
                )}
                <StyledSpacer size="32px" />
              </>
            )}
            {inputFields[page].map(renderItem)}
            {page === 2 && (
              <LanguageSection
                formValues={formValues}
                setFormValues={setFormValues}
                selectValue={selectValue}
                isMine={isMine}
                spouseFirstName={spouseFirstName}
              />
            )}
            <StyledSpacer size="48px" />
            {step < 2 && (
              <>
                <EuiButton
                  className="btn-text"
                  type="submit"
                  color="primary"
                  fill
                  onClick={step === (isMarried ? 1 : 0) ? finish : onNext}
                >
                  {step === (isMarried ? 1 : 0) ? "Finish" : "Next"}
                </EuiButton>

                {!(isMarried && step === 0) && (
                  <StyledEuiButtonEmpty
                    className="continue-button"
                    type="submit"
                    color="primary"
                    onClick={onNext}
                  >
                    Continue Human Capital
                  </StyledEuiButtonEmpty>
                )}

                {isMarried && step === 0 && (
                  <EuiButtonEmpty
                    className="btn-text"
                    type="submit"
                    color="text"
                    onClick={saveAndExit}
                  >
                    Save and Exit
                  </EuiButtonEmpty>
                )}
              </>
            )}
            {step >= 2 && (
              <>
                <EuiButton
                  className="btn-text"
                  type="submit"
                  color={isFinalStep ? "text" : "primary"}
                  fill={isFinalStep ? false : true}
                  onClick={isFinalStep ? finish : onNext}
                  style={isFinalStep ? { border: "1px solid #343741" } : {}}
                >
                  {isFinalStep ? "Finish" : "Next"}
                </EuiButton>

                {!isFinalStep && (
                  <StyledEuiButton color="text" onClick={finish}>
                    Finish
                  </StyledEuiButton>
                )}
              </>
            )}
          </Col>
        </StyledFormRow>
      </MainContainer>
    ),
    onPrev,
    onNext,
  });
};

export default MainForm;
