import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Col } from "react-bootstrap";
import { EuiButton, EuiButtonEmpty, EuiText, EuiTitle } from "@elastic/eui";

import { useFlyout } from "src/components/Global/FlyoutContext";
import {
  StyledEuiButton,
  StyledFormRow,
  StyledSpacer,
  StyledEuiTitle,
  StyledEuiButtonEmpty,
} from "src/components/Global/StyledComponents";
import { MappedAccount, ViewComponent } from "src/interfaces";
import { setProfileStep } from "src/store/profileBuild/actions";
import { PROFILE_BUILD_STEPS } from "src/store/profileBuild/constants";
import AccountsTable from "./AccountsTable";
import AddAccountDialog from "./AddAccountDialog";
import { closeTourCard } from "src/store/tourcard/actions";
import AddManualAccountForm from "./AddManualAccountForm";

import { MainContainer } from "../../components";

const ProfileDebts: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const { openCustomFlyout } = useFlyout();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  if (!render) {
    return <div />;
  }

  const onPrev = () => {
    dispatch(
      setProfileStep({
        step: PROFILE_BUILD_STEPS.EXPENSES,
        updateProgress: false,
      })
    );
    dispatch(closeTourCard());
  };

  const next = () => {
    dispatch(
      setProfileStep({ step: PROFILE_BUILD_STEPS.ASSETS, updateProgress: true })
    );
    dispatch(closeTourCard());
    if (mainContainerRef.current) {
      mainContainerRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const openAddFlyout = () => {
    setAddDialogOpen(false);
    dispatch(closeTourCard());
    openCustomFlyout({
      title: "Add manual account",
      component: AddManualAccountForm,
      isDebt: true,
    });
  };

  const openEditFlyout = (account: MappedAccount) => {
    openCustomFlyout({
      title: "Edit account",
      component: AddManualAccountForm,
      isDebt: true,
      selected: account,
    });
  };

  const openAddDialog = () => setAddDialogOpen(true);

  const exit = () => dispatch(push("/profile-builder-continue"));

  return render({
    component: (
      <MainContainer fluid ref={mainContainerRef}>
        <StyledFormRow>
          <Col lg={{ span: 9, offset: 2 }}>
            <EuiButtonEmpty
              color="text"
              flush="left"
              iconType="arrowLeft"
              onClick={onPrev}
            >
              Back
            </EuiButtonEmpty>

            <StyledSpacer size="32px" />

            <EuiTitle size="l" className="header-font">
              <h1>Your debts</h1>
            </EuiTitle>

            <StyledSpacer size="32px" />

            <EuiText size="m">
              <p>
                Include any student loans, credit card debts, and other debts.
              </p>
            </EuiText>

            <StyledSpacer size="36px" />

            <StyledEuiTitle size="xs">
              <h3>Add Debt</h3>
            </StyledEuiTitle>

            <StyledSpacer size="12px" />

            <EuiText size="m">
              <p>
                Connect your accounts for updated financial info or add your
                accounts manually.
              </p>
            </EuiText>

            <StyledSpacer size="32px" />

            <StyledEuiButton
              color="text"
              iconType="plus"
              onClick={openAddDialog}
            >
              Add debt account
            </StyledEuiButton>

            <AccountsTable
              showDebts
              openForEditing={(account) => openEditFlyout(account)}
            />

            <StyledSpacer size="48px" />

            <EuiButton
              type="submit"
              color="primary"
              fill
              onClick={next}
              className="btn-text"
            >
              Next
            </EuiButton>

            <StyledEuiButtonEmpty color="text" onClick={exit}>
              Save and Exit
            </StyledEuiButtonEmpty>
          </Col>
        </StyledFormRow>

        {addDialogOpen && (
          <AddAccountDialog
            openAddFlyout={openAddFlyout}
            onClose={() => setAddDialogOpen(false)}
            title="Add debt account"
          />
        )}
      </MainContainer>
    ),
  });
};

export default ProfileDebts;
