import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiIcon,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui";
import { getPlaidPublicTokenApi } from "src/apiService";
import { getIsMarried } from "src/store/system/selector";
import styled from "@emotion/styled";
import AddAccountDialogControls from "./AddAccountDialogControls";

export const StyledEuiModalHeader = styled(EuiModalHeader)`
  padding-inline: 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
  width: 24px;
  height: 24px;
`;

interface Props {
  openAddFlyout: VoidFunction;
  onClose: VoidFunction;
  title: string;
}

const AddAccountDialog = ({ openAddFlyout, onClose, title }: Props) => {
  const isMarried = useSelector(getIsMarried);
  const modalTitleId = useGeneratedHtmlId();
  const [userToken, setUserToken] = useState("");
  const [spouseToken, setSpouseToken] = useState("");
  const [showDialog, setShowDialog] = useState(true);

  useEffect(() => {
    getPlaidPublicTokenApi({ who: "applicant" }).then((data) => {
      setUserToken(data?.link_token || "");
    });
    if (isMarried) {
      getPlaidPublicTokenApi({ who: "spouse" }).then((data) => {
        setSpouseToken(data?.link_token || "");
      });
    }
  }, []);

  if (!showDialog) {
    return null;
  }

  const ready = !!userToken && (!isMarried || !!spouseToken);
  return (
    <EuiModal
      aria-labelledby={modalTitleId}
      onClose={onClose}
      style={{ maxWidth: 600 }}
    >
      <StyledEuiModalHeader>
        <EuiModalHeaderTitle id={modalTitleId}>{title}</EuiModalHeaderTitle>
        <StyledEuiIcon
          type="cross"
          onClick={onClose}
          style={{
            cursor: "pointer",
          }}
        />
      </StyledEuiModalHeader>
      <EuiModalBody>
        <EuiText size="m">
          <p>
            We use Plaid to connect your financial accounts quickly and
            securely. Once your accounts are connected, they will update
            automatically so you have the most complete picture of your
            finances. You also have the option of adding your accounts manually
            instead.
          </p>
        </EuiText>
      </EuiModalBody>
      {ready && (
        <AddAccountDialogControls
          openAddFlyout={openAddFlyout}
          onClose={onClose}
          userToken={userToken}
          spouseToken={spouseToken}
          setShowDialog={setShowDialog}
        />
      )}
    </EuiModal>
  );
};

export default AddAccountDialog;
