import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";

import { EuiButton, EuiContextMenu, EuiPopover } from "@elastic/eui";
import { spouseSelector, userSelector } from "src/store/system/selector";

interface Props {
  onClose: VoidFunction;
  userToken: string;
  spouseToken: string;
  setShowDialog: any;
  onSuccess: any;
}

const MarriedPlaidControl = ({
  onClose,
  userToken,
  spouseToken,
  setShowDialog,
  onSuccess,
}: Props) => {
  const user = useSelector(userSelector);
  const spouse = useSelector(spouseSelector);
  const myName = user?.first_name;
  const spouseName = spouse?.first_name;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const iconType = popoverOpen ? "arrowUp" : "arrowDown";

  const userConfig: any = {
    token: userToken,
    onSuccess: onSuccess("applicant"),
    onExit: onClose,
  };

  const spouseConfig: any = {
    token: spouseToken,
    onSuccess: onSuccess("spouse"),
    onExit: onClose,
  };

  const { open: userOpen } = usePlaidLink(userConfig);
  const { open: spouseOpen } = usePlaidLink(spouseConfig);

  const openPlaidApplicant = () => {
    setShowDialog(false);
    userOpen();
  };

  const openPlaidSpouse = () => {
    setShowDialog(false);
    spouseOpen();
  };

  return (
    <EuiPopover
      className="popover-styles"
      button={
        <EuiButton
          iconSide="right"
          fill
          iconType={iconType}
          onClick={() => setPopoverOpen(true)}
        >
          Connect account
        </EuiButton>
      }
      isOpen={popoverOpen}
      closePopover={() => setPopoverOpen(false)}
      hasArrow={false}
    >
      <EuiContextMenu
        initialPanelId={0}
        panels={[
          {
            id: 0,
            title: "",
            items: [
              {
                name: `${myName || ""}'s account`,
                onClick: openPlaidApplicant as any,
              },
              {
                name: `${spouseName || ""}'s account`,
                onClick: openPlaidSpouse as any,
              },
            ],
          },
        ]}
      />
    </EuiPopover>
  );
};

export default MarriedPlaidControl;
