import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { EuiModalFooter } from "@elastic/eui";
import { StyledEuiButtonEmpty } from "src/components/Global/StyledComponents";
import { addPublicTokenApi, fetchAccountProvidersApi } from "src/apiService";
import { AccountProvider } from "src/interfaces";
import { getAccounts, GET_ACCOUNT_PROVIDERS } from "src/store/account/actions";
import { SUCCESS } from "src/store/common";
import { getIsMarried } from "src/store/system/selector";
import { track } from "src/utils/tracking";
import SinglePlaidControl from "./SinglePlaidControl";
import MarriedPlaidControl from "./MarriedPlaidControl";

interface Props {
  openAddFlyout: VoidFunction;
  onClose: VoidFunction;
  userToken: string;
  spouseToken: string;
  setShowDialog: any;
}

const AddAccountControls = ({
  openAddFlyout,
  onClose,
  userToken,
  spouseToken,
  setShowDialog,
}: Props) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);

  const onSuccess = (who: string) => {
    // TODO make sure you're polling for linked account updates
    const successFunc = async (public_token: string) => {
      try {
        await addPublicTokenApi({ public_token, who });
        const data: AccountProvider[] = await fetchAccountProvidersApi();
        dispatch({
          type: GET_ACCOUNT_PROVIDERS + SUCCESS,
          payload: {
            providers: data,
          },
        });
        dispatch(getAccounts());
        // track Plaid successful account link in google data layer
        track({ event: "linked_plaid_account" });
        // setWaitingOnNewProvider(true);
      } catch (error) {
        console.log({ error });
        console.error(error);
      } finally {
        onClose();
      }
    };
    return successFunc;
  };

  return (
    <EuiModalFooter>
      <StyledEuiButtonEmpty onClick={openAddFlyout}>
        Add account manually
      </StyledEuiButtonEmpty>
      {isMarried ? (
        <MarriedPlaidControl
          userToken={userToken}
          spouseToken={spouseToken}
          onSuccess={onSuccess}
          setShowDialog={setShowDialog}
          onClose={onClose}
        />
      ) : (
        <SinglePlaidControl
          userToken={userToken}
          onSuccess={onSuccess}
          setShowDialog={setShowDialog}
          onClose={onClose}
        />
      )}
    </EuiModalFooter>
  );
};

export default AddAccountControls;
