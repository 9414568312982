import produce from "immer";
import { merge } from "lodash";
import { handleActions } from "redux-actions";

import {
  FbAction,
  Household,
  ProfileState,
  Profile,
  Item0,
  Item1,
} from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import * as actions from "./actions";
import * as oldActions from "../oldProfileBuild/actions";
import { LOG_OUT } from "../system/actions";
import { PROFILE_BUILD_STEPS, PROFILE_TYPE } from "./constants";

export const BLANK_PROFILE: Profile = {
  alt_email: "string",
  phone_number: null,
  dob_month: 0,
  dob_day: 0,
  dob_year: 0,
  spouse_first_name: "string",
  spouse_last_name: "string",
  sex: "o",
  aa_deg: "n",
  aa_grad_month: 0,
  aa_grad_year: 0,
  aa_field: 0,
  aa_school: 0,
  undergrad: "n",
  undergrad_grad_month: 0,
  undergrad_grad_year: 0,
  undergrad_field: 0,
  undergrad_school: 0,
  undergrad_gpa: "CA",
  undergrad_internships: 0,
  highest_degree_education: 0,
  adv_deg: "n",
  date_last_school_month: 0,
  date_last_school_year: 0,
  adid: 0,
  adv_school: 0,
  adv_school_other: 0,
  adv_deg_gpa: 0,
  adv_deg_exam: "string",
  adv_second_deg: "n",
  adv_second_deg_name: "string",
  adv_deg_school: "string",
  adv_speciality: 0,
  adv_speciality_other: "string",
  adv_speciality_other_yn: "n",
  grad_internships: 0,
  phd_deg: "n",
  phd_grad_month: 0,
  phd_grad_year: 0,
  phd_field: 0,
  phd_school: 0,
  profession: "string",
  pt_employ_setting: 0,
  years_work_exp: 0,
  number_designations: 0,
  fellowship: 0,
  residency: 0,
  designation_name_1: "string",
  designation_name_2: "string",
  designation_name_3: "string",
  designation_name_4: "string",
  designation_name_5: "string",
  lang: "n",
  lang_1: "string",
  lang_speaking_1: "1",
  lang_reading_1: "1",
  lang_writing_1: "1",
  lang_2: "string",
  lang_speaking_2: "1",
  lang_reading_2: "1",
  lang_writing_2: "1",
  qual_gifted_and_talented: "n",
  qual_intl_bacc: "n",
  qual_ap_scholar: "n",
  qual_scholarship: "n",
  qual_full_scholarship: "n",
  qual_military: "n",
  qual_military_yrs: 0,
  qual_military_leader: "n",
  qual_peace_corps: "n",
  qual_church: "n",
  qual_church_yrs: 0,
  qual_church_leader: "n",
  qual_community: "n",
  qual_community_yrs: 0,
  qual_community_leader: "n",
  qual_fin_proactive: "n",
  qual_hospital: "n",
  qual_hospital_yrs: 0,
  qual_hospital_leader: "n",
  qual_nonprofit: "n",
  qual_nonprofit_yrs: 0,
  qual_nonprofit_leader: "n",
  qual_internship: null,
  qual_trade_group: null,
  qual_trade_group_leader: null,
  qual_team_leader: null,
  qual_networking: null,
  qual_networking_leader: "n",
  qual_articles: "n",
  qual_articles_qty: 0,
  qual_speaking: null,
  qual_speaking_qty: 0,
  qual_work_prog: "n",
  qual_work_prog_qty: 0,
  qual_work_prog_leader: "n",
  qual_director: null,
  qual_license_exam: null,
  qual_desig_exam: null,
  qual_club_sports: "n",
  qual_club_sports_yrs: 0,
  qual_club_sports_leader: "n",
  qual_ncaa_sports: "n",
  qual_ncaa_sports_yrs: 0,
  qual_ncaa_sports_d1: "n",
  qual_ncaa_sports_leader: "n",
  qual_prof_sports: "n",
  qual_prof_sports_yrs: 0,
  qual_prof_sports_leader: "n",
  qual_hobby_sports: "n",
  qual_hobby_sports_yrs: 0,
  qual_marathons: "n",
  qual_marathons_qty: 0,
  qual_electronics: "n",
  qual_electronics_yrs: 0,
  qual_cs: "n",
  qual_cs_yrs: 0,
  qual_web: "n",
  qual_web_yrs: 0,
  qual_blog: "n",
  qual_blog_yrs: 0,
  qual_entrep: "n",
  qual_entrep_yrs: 0,
  qual_diy: "n",
  qual_diy_yrs: 0,
  qual_craft: "n",
  qual_craft_yrs: 0,
  qual_autobody: "n",
  qual_autobody_yrs: 0,
  fed_repayment_plan: "std_plan",
  student_loan_prepayments: "n",
  perkins_lf: "n",
  delinquency: "n",
  delinquency_mths: 0,
};

const BLANK_HOUSEHOLD: Household = {
  street_address: "953 Hillcrest Drive",
  city: "Beverly Hills",
  state: "CA",
  zip: "90210",
  children: [],
  children_qty: 0,
  child_1_age: 0,
  child_2_age: 0,
  child_3_age: 0,
  child_4_age: 0,
  child_5_age: 0,
  pension: "n",
  disable_insure: "n",
  qual_life_insure: "n",
  life_insure_death_benefit_value: 0,
  whole_life_insure_cash_value: 0,
  qual_will: "n",
  qual_trust: "n",
  filing_jointly: "n",
};

const initialState: ProfileState = {
  currentStep: PROFILE_BUILD_STEPS.WELCOME,
  profile: BLANK_PROFILE,
  spouseProfile: BLANK_PROFILE,
  household: BLANK_HOUSEHOLD,
  progress: 0,
  loaded: {},
  loading: {},
  error: {},
  schools: [],
  schoolsByLevel: {},
  specialties: [],
  fields: [],
  professions: [],
  employments: [],
  fellowships: [],
  residencies: [],
  designations: [],
  apiMessage: "",
  message: "",
};

const receiveProfileMerge = (
  state: ProfileState,
  { payload }: FbAction<{ who?: PROFILE_TYPE; update: Partial<Profile> }>
) =>
  produce(state, (draft) => {
    draft.loading.profile = false;
    draft.loaded.profile = true;
    draft.error.profile = null;
    if (payload.who === PROFILE_TYPE.SPOUSE) {
      merge(draft.spouseProfile, payload.update);
    } else {
      merge(draft.profile, payload.update);
    }
  });

const setLoadingProfile = (state: ProfileState) =>
  produce(state, (draft) => {
    draft.loading.profile = true;
    draft.error.profile = null;
  });
const setErrorProfile = (state: ProfileState, { payload }: FbAction<any>) =>
  produce(state, (draft) => {
    draft.loading.profile = false;
    draft.error.profile = payload;
  });

const reducerDefinitions: any = {
  /* profile */
  [actions.FETCH_PROFILE + START]: setLoadingProfile,
  [actions.FETCH_SPOUSE_PROFILE + START]: setLoadingProfile,
  [actions.FETCH_PROFILE + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<{ profile?: Profile; spouse?: Profile }>
  ) =>
    produce(state, (draft) => {
      draft.loading.profile = false;
      draft.loaded.profile = true;
      draft.error.profile = null;
      if (payload.profile) {
        draft.profile = payload.profile;
      }
      if (payload.spouse) {
        draft.spouseProfile = payload.spouse;
      }
    }),
  [actions.FETCH_PROFILE + FAIL]: setErrorProfile,

  /* progress */
  [actions.GET_PROFILE_PROGRESS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.progress = true;
      draft.error.progress = null;
    }),
  [actions.GET_PROFILE_PROGRESS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.progress = false;
      draft.loaded.progress = true;
      draft.error.progress = null;
      draft.progress = payload;
    }),
  [actions.SET_PROFILE_PROGRESS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading.progress = false;
      draft.loaded.progress = true;
      draft.error.progress = null;
      draft.progress = payload;
    }),
  [actions.GET_PROFILE_PROGRESS + FAIL]: (
    state: ProfileState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.progress = false;
      draft.error.progress = payload;
    }),

  /* household */
  [actions.FETCH_HOUSEHOLD + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.household = true;
      draft.error.household = null;
    }),
  [actions.FETCH_HOUSEHOLD + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Household>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.loaded.household = true;
      draft.error.household = null;
      draft.household = payload;
    }),
  [actions.FETCH_HOUSEHOLD + FAIL]: (
    state: ProfileState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.error.household = payload;
    }),
  [actions.UPDATE_HOUSEHOLD + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.household = true;
      draft.error.household = null;
    }),
  [actions.UPDATE_HOUSEHOLD + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<actions.UpdateHouseholdPayload>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.household = { ...state.household, ...payload.update };
    }),
  [actions.UPDATE_HOUSEHOLD + FAIL]: (
    state: ProfileState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.error.household = payload;
    }),
  [oldActions.OLD_UPDATE_HOUSEHOLD + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.household = true;
      draft.error.household = null;
    }),
  [oldActions.OLD_UPDATE_HOUSEHOLD + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<actions.UpdateHouseholdPayload>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.household = { ...state.household, ...payload.update };
    }),
  [oldActions.OLD_UPDATE_HOUSEHOLD + FAIL]: (
    state: ProfileState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.loading.household = false;
      draft.error.household = payload;
    }),
  /* schools */
  [actions.FETCH_SCHOOLS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.schools = true;
      draft.error.schools = null;
    }),
  [actions.FETCH_SCHOOLS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item0>>
  ) =>
    produce(state, (draft) => {
      draft.loading.schools = false;
      draft.loaded.schools = true;
      draft.error.schools = null;
      draft.schools = payload;
    }),
  [actions.FETCH_SCHOOLS + FAIL]: (state: ProfileState, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading.schools = false;
      draft.error.schools = payload;
      draft.error = payload;
    }),

  /* schools (leveled) TODO: use constants instead */
  [actions.FETCH_SCHOOLS_BY_LEVEL + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.schoolsByLevel = true;
      draft.error.schoolsByLevel = null;
    }),
  [actions.FETCH_SCHOOLS_BY_LEVEL + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<any> // TODO: type
  ) =>
    produce(state, (draft) => {
      draft.loading.schoolsByLevel = false;
      draft.loaded.schoolsByLevel = true;
      draft.error.schoolsByLevel = null;
      draft.schoolsByLevel[payload.level] = payload.data;
    }),
  [actions.FETCH_SCHOOLS_BY_LEVEL + FAIL]: (
    state: ProfileState,
    { payload }: any
  ) =>
    produce(state, (draft) => {
      draft.loading.schoolsByLevel = false;
      draft.error.schoolsByLevel = payload;
    }),

  /* specialities */
  [actions.FETCH_SPECIALTIES + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.specialties = true;
      draft.error.specialties = null;
    }),
  [actions.FETCH_SPECIALTIES + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item0>>
  ) =>
    produce(state, (draft) => {
      draft.loading.specialties = false;
      draft.loaded.specialties = true;
      draft.error.specialties = null;
      draft.specialties = payload;
    }),
  [actions.FETCH_SPECIALTIES + FAIL]: (state: ProfileState, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading.specialties = false;
      draft.error.specialties = payload;
    }),

  /* fields */
  [actions.FETCH_FIELDS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.fields = true;
      draft.error.fields = null;
    }),
  [actions.FETCH_FIELDS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft) => {
      draft.loading.fields = false;
      draft.loaded.fields = true;
      draft.error.fields = null;
      draft.fields = payload;
    }),
  [actions.FETCH_FIELDS + FAIL]: (state: ProfileState, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading.fields = false;
      draft.error.fields = payload;
    }),

  /* professions */
  [actions.FETCH_PROFESSIONS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.professions = true;
      draft.loaded.professions = true;
      draft.error.professions = null;
    }),
  [actions.FETCH_PROFESSIONS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item1>> // TODO: type
  ) =>
    produce(state, (draft) => {
      draft.loading.professions = false;
      draft.error.professions = null;
      draft.professions = payload;
    }),
  [actions.FETCH_PROFESSIONS + FAIL]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.professions = false;
      draft.error.professions = null;
    }),

  /* employments */
  [actions.FETCH_EMPLOYMENTS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.employments = true;
      draft.error.employments = null;
    }),
  [actions.FETCH_EMPLOYMENTS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft) => {
      draft.loading.employments = false;
      draft.loaded.employments = true;
      draft.error.employments = null;
      draft.employments = payload;
    }),
  [actions.FETCH_EMPLOYMENTS + FAIL]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.employments = false;
      draft.error.employments = null;
    }),

  /* fellowships & residencies */
  [actions.FETCH_FELLOWSHIPS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.fellowships = true;
      draft.error.fellowships = null;
    }),
  [actions.FETCH_FELLOWSHIPS + SUCCESS]: (
    state: ProfileState,
    {
      payload,
    }: FbAction<{
      fellowship: Array<Item0>;
      residency: Array<Item0>;
    }>
  ) =>
    produce(state, (draft) => {
      draft.loading.fellowships = false;
      draft.loaded.fellowships = true;
      draft.error.fellowships = null;
      draft.fellowships = payload.fellowship;
      draft.residencies = payload.residency;
    }),
  [actions.FETCH_FELLOWSHIPS + FAIL]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.fellowships = false;
      draft.error.fellowships = null;
    }),

  /* designations */
  [actions.FETCH_DESIGNATIONS + START]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.designations = true;
      draft.error.designations = null;
    }),
  [actions.FETCH_DESIGNATIONS + SUCCESS]: (
    state: ProfileState,
    { payload }: FbAction<Array<Item0>> // TODO: type
  ) =>
    produce(state, (draft) => {
      draft.loading.designations = false;
      draft.loaded.designations = true;
      draft.error.designations = null;
      draft.designations = payload;
    }),
  [actions.FETCH_DESIGNATIONS + FAIL]: (state: ProfileState) =>
    produce(state, (draft) => {
      draft.loading.designations = false;
      draft.error.designations = null;
    }),
  [actions.SET_PROFILE_STEP]: (
    state: ProfileState,
    { payload }: FbAction<actions.SetProfileStepPayload>
  ) =>
    produce(state, (draft) => {
      draft.currentStep = payload.step;
    }),
  [LOG_OUT]: () => initialState,
};

[
  actions.UPDATE_PROFILE,
  actions.UPDATE_PERSONAL_INFO,
  actions.UPDATE_EDUCATION,
  actions.UPDATE_CAREER,
  actions.UPDATE_OTHER_HUMAN_CAPITAL,
  actions.UPDATE_DEBTS,
  oldActions.OLD_UPDATE_PROFILE,
  oldActions.OLD_UPDATE_PERSONAL_INFO,
  oldActions.OLD_UPDATE_EDUCATION,
  oldActions.OLD_UPDATE_CAREER,
  oldActions.OLD_UPDATE_OTHER_HUMAN_CAPITAL,
  oldActions.OLD_UPDATE_DEBTS,
].forEach((actionType) => {
  reducerDefinitions[actionType + START] = setLoadingProfile;
  reducerDefinitions[actionType + SUCCESS] = receiveProfileMerge;
  reducerDefinitions[actionType + FAIL] = setErrorProfile;
});

const profileBuildReducer = handleActions<ProfileState, any>(
  reducerDefinitions,
  initialState
);

export default profileBuildReducer;
